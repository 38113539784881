import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub as fabGithub } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer>
      <p>
        Derechos Reservados &copy; {new Date().getFullYear()}{" "}
        <b>
          <a href="http://cuspry.com">
            <FontAwesomeIcon icon={faGlobe} className="globe-icon" />
            <span> Cuspry inc.</span>
          </a>
        </b>
      </p>
      <p>
        Desarrollo de Pokedex con React{" "}
        <b>
          <a
            href="https://github.com/romaldo-cp/pokedex"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={fabGithub} className="github-icon" />
            <span style={{ marginLeft: "5px" }}>Repositorio en GitHub</span>
          </a>
        </b>
      </p>
    </footer>
  );
};

export default Footer;
